import React from 'react'
import logo1 from "../Images/logo1.svg";
import logo2 from "../Images/logo2.svg";
import logo3 from "../Images/logo3.svg";
import logo4 from "../Images/logo4.svg";
import logo5 from "../Images/logo5.svg";
import Marquee from "react-fast-marquee";
import profileimg from "../Images/Muhammad Qasim 3.png";
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";


function LastSec() {
  return (
    <>
      
          <div className="container-fluid home-sec-div5">
              <div className="container">
                  <div>
                      <h1 className="haveproject-text1">
                          {" "}
                          <Fade bottom cascade distance='55%'><img src={profileimg} alt="" className="profile-img1" /></Fade>  <Fade bottom cascade distance='55%'>have
                              projects </Fade> 
                          <div className="have-div1">
                              <span className='d-sm-inline have-project-span'> <Fade bottom cascade distance='55%'>in</Fade> </span>
                              <span className='have-project-span' > <Fade bottom cascade distance='55%'> mind?</Fade></span>
                              <Fade bottom cascade distance='55%'>
                                  <a href="mailto:weeqasim@gmail.com" className="haveproject-btn1">
                                  {" "}
                                  Get in Touch
                              </a>
                              </Fade>
                          </div>{" "}
                          <Fade bottom cascade distance='55%'><span className='d-inline have-project-span'> Let's work </span>together</Fade>
                      </h1>
                  </div>

                  {/* <div className="agency-div1">
                      <p className="agency-text1">I worked with different Agencies</p>

                      <div>
                          <div className="row work-company-maindiv mt-4">
                              <Marquee>
                                  <div className=" work-col-div">
                                      <a href="" target="_blank">
                                          <div className="work-company-div1 ">
                                              <div>
                                                  <img
                                                      src={logo1}
                                                      className="img-fluid company-logo1"
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>

                                  <div className=" work-col-div">
                                      <a href="" target="_blank">
                                          <div className="work-company-div1">
                                              <div>
                                                  <img
                                                      src={logo2}
                                                      className="img-fluid company-logo1"
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>

                                  <div className=" work-col-div">
                                      <a href="" target="_blank">
                                          <div className="work-company-div1">
                                              <div>
                                                  <img
                                                      src={logo3}
                                                      className="img-fluid company-logo1"
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>

                                  <div className=" work-col-div">
                                      <a href="" target="_blank">
                                          <div className="work-company-div1">
                                              <div>
                                                  <img
                                                      src={logo4}
                                                      className="img-fluid company-logo1"
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>

                                  <div className=" work-col-div">
                                      <a href="" target="_blank">
                                          <div className="work-company-div1">
                                              <div>
                                                  <img
                                                      src={logo5}
                                                      className="img-fluid company-logo1"
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                              </Marquee>
                          </div>
                      </div>
                  </div> */}

                  
              </div>
          </div>


    </>
  )
}

export default LastSec
