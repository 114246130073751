import React, { useRef, useEffect } from "react";
import "./Home.css";
import profileimg from "../Images/Muhammad Qasim 3.png";
import profileimg2 from "../Images/digital.jpeg";
import animpic from "../Images/divs.svg";
import arrowpic from "../Images/arror.svg";
import project1 from "../Images/Group 1000008197.png";
import project2 from "../Images/Group 1000008200.png";
import project3 from "../Images/Group 1000008199.png";
import project4 from "../Images/Group 1000008207.png";
import logo1 from "../Images/logo1.svg";
import logo2 from "../Images/logo2.svg";
import logo3 from "../Images/logo3.svg";
import logo4 from "../Images/logo4.svg";
import logo5 from "../Images/logo5.svg";
import Marquee from "react-fast-marquee";
import LastSec from "./LastSec";
import { Link } from "react-router-dom";
// import { TweenMax, Power3 } from "gsap";
import gsap from "gsap-trial";
import { SplitText } from "gsap-trial/SplitText";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
import { StaggerTextReveal } from "stagger-text-reveal-animation";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import TechStack from "./TechStack";



function Home({ timeline }) {

  useEffect(() => {
    //   timeline.from([h1, h2.children], 1, {
    //     opacity: 1,
    //     y: "100",
    //     skewY: 10,
    //     stagger: {
    //       amount: .4
    //     }
    //   }, "-=1")
    // gsap.registerPlugin(SplitText)
    // let mysplittext = new SplitText('.wonder-text1', { type: 'char' })
    // let chars = mysplittext.chars
    // gsap.from(chars, {
    //   yPercent: 130,
    //   stagger: 0.05,
    //   ease:'back-out',
    //   duration:1,
    // })
  });

  return (
    <>
      <div className="container-fluid home-sec-div1">
        <div className="container abc">
          {/* <Fade bottom cascade> */}
          <h1 className="profile-text1" id="profile-text1">
            <Fade bottom cascade distance='60%' >
              i'm Qasim
              {" "}
              <img
                src={profileimg}
                alt=""
                className="profile-img1 d-inline"
              />
            </Fade>
            {" "}
            <span className="profile-text1-span1" >
              <Fade bottom cascade distance='60%' >
                Creative
              </Fade>
            </span>

            <br className="d-lg-block d-none" />
{' '}
            <span className="profile-text1-span1" >
              <Fade bottom cascade distance='60%'  >
                  digital
              </Fade>
            </span>
            {" "}
            <Fade bottom cascade distance='60%' >
              <img src={profileimg2} alt="" className="profile-img2 d-inline" />
            </Fade>
            {" "}
            <Fade bottom cascade distance='60%' >
              Designer
            </Fade>
            {" "}
          </h1>
          {/* </Fade> */}

          <div className="home-btn-div1 d-flex align-items-center justify-content-center">
            <Link to="/works" className="view-all-btn1">
              <svg className="btn-svg11" xmlns="http://www.w3.org/2000/svg" width="56" height='56'  viewBox="0 0 60 60" fill="none">
                <circle cx="27" cy="27" r="27" fill="#F0F2F5" />
              </svg>
              View Portfolio
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid pl-0 pr-0">
        <div className="animation-div1">
          <img src={animpic} alt="" className="animtaion-pic1" />
        </div>
        <div className="home-sec-div2">
          <div className="container ">
            {/* <Fade bottom cascade> */}

            <h1 className="wonder-text1" id="wonder-text1">
              <Fade bottom cascade distance='60%' >
                i craft wonderful
              </Fade>
              <div className="wonder-text-div2">

                <div className="wonder-text-div1"></div>
                <Fade bottom cascade distance='60%'>

                  digital
                  <span className="d-sm-inline d-none "> experiences</span>
                </Fade>
              </div>
              <Fade bottom cascade distance='60%'>
                <span className="d-inline d-sm-none"> experiences</span> for
                brands
              </Fade>
            </h1>

            {/* </Fade> */}

            <div className="home-sec-text2">
              <p>
                I am, a User Experience Designer specializing in branding, marketing creatives and creating user-friendly and massage-focused website & app designs. I am capable within a wide range of tactical and big-picture design tasks. I am passionate about making sure that systems, interfaces, language and graphics are human-friendly, emotive, aesthetically pleasing, clear, on-brand and usable — with a touch of mellow smoothness.
              </p>
            </div>
            <div className="d-flex justify-content-end ml-4">
              <div className='home-aout-divs11'>
              <h1 className="about-year-text1 mb-0">Year of <br /> Experience </h1>
              <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
                {({ isVisible }) => (
                  <div style={{ height: 100 }}>
                    {isVisible ? (
                      <CountUp
                        end={10}
                        duration={2}
                        className="home-about-year-text2"
                        start={1}
                      />
                    ) : null}
                  </div>
                )}
              </VisibilitySensor>
              <Link to="/about" className="more-text1">
                More about me <img src={arrowpic} alt="" />
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid home-sec-div3">
        <div className="container">
          <div>
            <h1 className="what-text1"> <Fade bottom cascade distance='60%'>What i do</Fade></h1>
            <p className="selected-para1 pl-0">
              I am specialised in branding, marketing creatives and UI UX design
            </p>
          </div>

          <div className="row cards-div1">
            <div className="col-md-4 col-12">
              <Fade bottom duration={2000}>
                <div className="card-text-div1">
                  <div className="circle-icon1">
                    <div className="circle-icon1-div1"></div>
                    <div className="circle-icon1-div2"></div>
                    <div className="circle-icon1-div3"></div>
                  </div>
                  <div>
                    <h1 className="concept-text1">Branding</h1>
                  </div>
                  <div>
                    <p className="concept-text2">
                      From crafting compelling logos and visual elements to developing a cohesive brand strategy.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-md-4 col-12">
              <Fade bottom duration={2000} delay={300}>
                <div className="card-text-div2">
                  <div className="circle-icon1">
                    <div className="circle-icon2-div1"></div>
                    <div className="circle-icon2-div2"></div>
                    <div className="circle-icon2-div3"></div>
                  </div>
                  <div>
                    <h1 className="concept-text1">UI UX Design</h1>
                  </div>
                  <div>
                    <p className="concept-text2">
                      I optimize the user journey, enhance usability, and deliver visually stunning and user-friendly digital solutions.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-md-4 col-12">
              <Fade bottom duration={2000} delay={500}>
                <div className="card-text-div3">
                  <div className="circle-icon1">
                    <div className="circle-icon3-div1"></div>
                    <div className="circle-icon3-div2"></div>
                    <div className="circle-icon3-div3"></div>
                  </div>
                  <div>
                    <h1 className="concept-text1">Marketing Creatives</h1>
                  </div>
                  <div>
                    <p className="concept-text2">
                     Marketing creatives elevate brand's visual presence and captivate audience across various channels.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>

      </div>

    
      <TechStack></TechStack>
      <LastSec></LastSec>
     
    </>
  );
}

export default Home;
