import React from 'react'
import './TechStack.css'
import Fade from "react-reveal/Fade";


function TechStack() {
  return (
    <div>
        <div className='container-fluid techstack-div1'>
            <div className='container'>
                  <h1 className='tech-text1'> <Fade bottom cascade distance='60%' >Tech Stack I use</Fade></h1>
                  <p className='tech-text2'>I am proficient in adobe photoshop, illustrator, adobe XD, Figma, Sketch, <br></br> WordPress, Shopify, HTML, and CSS. But I do have hands on experience in Webflow,<br></br> Primer Pro, and After Effects.</p>
                <div className='row techstack-div3'>
                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 103 100" fill="none">
                              <path d="M18.1624 0.5H84.4017C94.1683 0.5 102.064 8.3958 102.064 18.1624V81.8376C102.064 91.6042 94.1683 99.5 84.4017 99.5H18.1624C8.3958 99.5 0.5 91.6042 0.5 81.8376V18.1624C0.5 8.3958 8.3958 0.5 18.1624 0.5Z" stroke="white" />
                              <path d="M23.0769 70.1284V26.154C23.0769 25.8548 23.2051 25.6839 23.5043 25.6839C24.2308 25.6839 24.9145 25.6839 25.8974 25.6412C26.9231 25.5984 27.9914 25.5984 29.1453 25.5557C30.2991 25.513 31.5384 25.513 32.8632 25.4702C34.188 25.4275 35.4701 25.4275 36.7521 25.4275C40.2564 25.4275 43.1624 25.8548 45.5555 26.7523C47.6923 27.4788 49.6581 28.6754 51.282 30.2566C52.6496 31.6241 53.7179 33.2907 54.4017 35.1283C55.0427 36.9232 55.3846 38.7608 55.3846 40.6839C55.3846 44.3591 54.5299 47.3933 52.8205 49.7865C51.1111 52.1796 48.7179 53.9745 45.9402 55.0001C43.0342 56.0685 39.829 56.4531 36.3248 56.4531C35.2991 56.4531 34.6154 56.4531 34.188 56.4104C33.7607 56.3677 33.1624 56.3677 32.3504 56.3677V70.0856C32.3931 70.3848 32.1795 70.6412 31.8803 70.6839C31.8376 70.6839 31.7949 70.6839 31.7094 70.6839H23.5897C23.2478 70.6839 23.0769 70.513 23.0769 70.1284ZM32.3931 33.9318V48.2907C32.9914 48.3335 33.547 48.3762 34.0598 48.3762H36.3248C37.9914 48.3762 39.6581 48.1198 41.2393 47.607C42.6068 47.2224 43.8034 46.4104 44.7436 45.342C45.641 44.2736 46.0684 42.8207 46.0684 40.9403C46.1111 39.6155 45.7692 38.2907 45.0855 37.1369C44.359 36.0258 43.3333 35.1711 42.094 34.701C40.5128 34.06 38.8034 33.8036 37.0513 33.8463C35.9402 33.8463 34.9573 33.8463 34.1453 33.889C33.2906 33.8463 32.6923 33.889 32.3931 33.9318Z" fill="white" />
                              <path d="M82.0514 45.6838C80.7693 45 79.4018 44.5299 77.9488 44.2308C76.3676 43.8889 74.7864 43.6752 73.1625 43.6752C72.3078 43.6325 71.4103 43.7607 70.5984 43.9744C70.0428 44.1026 69.5727 44.4017 69.2736 44.8291C69.0599 45.1709 68.9317 45.5983 68.9317 45.9829C68.9317 46.3675 69.1027 46.7521 69.3591 47.094C69.7437 47.5641 70.2565 47.9487 70.812 48.2479C71.795 48.7607 72.8206 49.2308 73.8462 49.6581C76.1539 50.4273 78.3762 51.4957 80.4274 52.7778C81.8377 53.6752 82.9915 54.8718 83.8035 56.3248C84.4873 57.6923 84.8291 59.188 84.7864 60.7265C84.8291 62.735 84.2309 64.7436 83.1197 66.4103C81.9232 68.1197 80.2565 69.4444 78.3334 70.2137C76.2394 71.1111 73.6753 71.5812 70.5984 71.5812C68.6326 71.5812 66.7095 71.4103 64.7864 71.0256C63.2907 70.7692 61.795 70.2991 60.4274 69.6581C60.1283 69.4872 59.9146 69.188 59.9573 68.8462V61.4103C59.9573 61.282 60.0001 61.1111 60.1283 61.0256C60.2565 60.9402 60.3847 60.9829 60.5129 61.0684C62.1796 62.0513 63.9317 62.735 65.8121 63.1624C67.436 63.5897 69.1454 63.8034 70.8548 63.8034C72.4787 63.8034 73.6326 63.5897 74.4018 63.2051C75.0856 62.906 75.5556 62.1795 75.5556 61.4103C75.5556 60.812 75.2138 60.2564 74.53 59.7009C73.8462 59.1453 72.436 58.5043 70.342 57.6923C68.1625 56.9231 66.1539 55.8974 64.2736 54.6154C62.9488 53.6752 61.8377 52.4359 61.0257 50.9829C60.342 49.6154 60.0001 48.1197 60.0428 46.6239C60.0428 44.7863 60.5556 43.0342 61.4958 41.453C62.5642 39.7436 64.1454 38.3761 65.983 37.5214C67.9915 36.4957 70.5129 36.0256 73.5471 36.0256C75.2992 36.0256 77.0941 36.1538 78.8462 36.4102C80.1283 36.5812 81.3676 36.9231 82.5215 37.3932C82.6924 37.4359 82.8633 37.6068 82.9488 37.7778C82.9915 37.9487 83.0343 38.1197 83.0343 38.2906V45.2564C83.0343 45.4273 82.9488 45.5983 82.8206 45.6838C82.436 45.7692 82.2223 45.7692 82.0514 45.6838Z" fill="#D2D2D2" />
                          </svg>
                          <p className='tech-text3'>Photoshop</p>
                    </div>
                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 104 100" fill="none">
                              <path d="M69.4105 31.41C70.4362 32.3929 71.8037 32.9484 73.214 32.9057C74.667 32.9912 76.1199 32.4356 77.1029 31.41C78.1285 30.3416 78.6413 28.9313 78.5986 27.4783C78.6413 26.0681 78.1285 24.7006 77.1883 23.6749C76.2481 22.692 74.9661 22.1792 73.2994 22.1792C71.8892 22.1792 70.5217 22.692 69.496 23.6749C68.4704 24.6578 67.9148 26.0254 67.9575 27.4783C67.9148 28.9313 68.4276 30.3416 69.4105 31.41Z" fill="#D2D2D2" />
                              <path d="M68.4276 37.0937V69.9997C68.4276 70.4271 68.6413 70.6835 69.0687 70.6835H77.4447C77.8293 70.6835 78.0003 70.4698 78.0003 69.9997V37.0937C78.0003 36.7091 77.8293 36.4954 77.4447 36.4954H68.9832C68.5986 36.4954 68.4276 36.6664 68.4276 37.0937Z" fill="#D2D2D2" />
                              <path d="M18.7266 0.5H84.9659C94.7325 0.5 102.628 8.3958 102.628 18.1624V81.8376C102.628 91.6042 94.7325 99.5 84.9659 99.5H18.7266C8.96001 99.5 1.06421 91.6042 1.06421 81.8376V18.1624C1.06421 8.3958 8.96001 0.5 18.7266 0.5Z" stroke="white" />
                              <path d="M50.137 59.8285H34.2396L30.9917 69.8712C30.9063 70.2558 30.5644 70.5122 30.1798 70.4695H22.1456C21.6755 70.4695 21.5473 70.2131 21.6755 69.7003L35.4362 30.0849C35.5644 29.6575 35.6926 29.1874 35.8635 28.6746C36.0345 27.7772 36.1199 26.837 36.1199 25.8968C36.0772 25.6832 36.2481 25.4695 36.4618 25.4268H47.5302C47.8721 25.4268 48.043 25.555 48.0857 25.7686L63.684 69.7857C63.8122 70.2558 63.684 70.4695 63.2567 70.4695H54.3251C54.0259 70.5122 53.7268 70.2986 53.6413 69.9994L50.137 59.8285ZM36.7182 51.1533H47.5729C47.3165 50.2558 46.9746 49.1874 46.59 48.0763C46.2054 46.9225 45.8208 45.6832 45.4362 44.4011C45.0088 43.0763 44.6242 41.7943 44.1969 40.4695C43.7695 39.1447 43.3849 37.9054 43.043 36.6661C42.7011 35.4695 42.402 34.3584 42.1028 33.3327H42.0174C41.6328 35.1704 41.1627 37.008 40.5644 38.8456C39.9233 40.8968 39.2823 43.0336 38.5986 45.1704C38.0003 47.3498 37.3592 49.3584 36.7182 51.1533Z" fill="white" />
                          </svg>
                          <p className='tech-text3'>Illustrator</p>
                      </div>
                       <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 103 100" fill="none">
                              <g clip-path="url(#clip0_1573_358)">
                                  <path d="M18.1907 0.5H84.0657C93.7744 0.5 101.628 8.3932 101.628 18.1624V81.8376C101.628 91.6068 93.7744 99.5 84.0657 99.5H18.1907C8.48191 99.5 0.628174 91.6068 0.628174 81.8376V18.1624C0.628174 8.3932 8.48191 0.5 18.1907 0.5Z" stroke="white" />
                                  <path d="M53.7632 26.2821L41.0132 47.436L54.6132 69.8719C54.6982 70.0428 54.7407 70.2138 54.6982 70.3847C54.6557 70.5556 54.4857 70.4274 54.2307 70.4702H44.4982C43.8182 70.4702 43.3507 70.4274 43.0532 70.0001C42.1607 68.2052 41.2257 66.4531 40.3332 64.6582C39.4407 62.9061 38.4632 61.1112 37.4432 59.2736C36.4232 57.436 35.4032 55.5984 34.3832 53.718H34.2982C33.4057 55.5556 32.4282 57.3933 31.4507 59.2309C30.4732 61.0685 29.4957 62.9061 28.5607 64.7009C27.5832 66.4958 26.6057 68.3334 25.6282 70.0856C25.4582 70.5129 25.1182 70.5556 24.6507 70.5556H15.3007C15.1307 70.5556 15.0032 70.6411 15.0032 70.4274C14.9607 70.2565 15.0032 70.0856 15.0882 69.9574L28.3057 48.1625L15.4282 26.2394C15.3007 26.0685 15.2582 25.8975 15.3432 25.8121C15.4282 25.6838 15.5982 25.6411 15.7682 25.6411H25.4157C25.6282 25.6411 25.8407 25.6838 26.0107 25.7266C26.1807 25.8121 26.3082 25.9403 26.4357 26.1112C27.2432 27.9488 28.1782 29.7864 29.1557 31.624C30.1757 33.4616 31.1532 35.2565 32.2157 37.0514C33.2357 38.8462 34.1707 40.6411 35.0632 42.4787H35.1482C36.0407 40.5984 36.9757 38.7608 37.9107 36.9659C38.8457 35.171 39.8232 33.3762 40.8007 31.5813C41.7782 29.7864 42.7132 27.9488 43.6482 26.1967C43.6907 26.0257 43.7757 25.8548 43.9032 25.7693C44.0732 25.6838 44.2432 25.6411 44.4557 25.6838H53.4232C53.6357 25.6411 53.8482 25.7693 53.8907 25.983C53.9332 26.0257 53.8482 26.1967 53.7632 26.2821Z" fill="white" />
                                  <path d="M73.3982 71.3676C70.2532 71.4103 67.1082 70.7693 64.2607 69.4445C61.5832 68.2052 59.3732 66.1539 57.8432 63.6325C56.2707 61.0257 55.5057 57.7778 55.5057 53.889C55.4632 50.7266 56.2707 47.6069 57.8432 44.8719C59.4582 42.0941 61.7957 39.7864 64.6007 38.2479C67.5757 36.5813 71.1457 35.7693 75.3532 35.7693C75.5657 35.7693 75.8632 35.7693 76.2457 35.812C76.6282 35.8548 77.0532 35.8548 77.5632 35.8975V22.3932C77.5632 22.0941 77.6907 21.9231 77.9882 21.9231H86.6157C86.8282 21.8804 86.9982 22.0514 87.0407 22.2223C87.0407 22.265 87.0407 22.3078 87.0407 22.3078V62.9915C87.0407 63.7608 87.0832 64.6155 87.1257 65.5556C87.2107 66.4531 87.2532 67.3078 87.2957 68.0343C87.2957 68.3334 87.1682 68.5898 86.8707 68.718C84.6607 69.6582 82.3232 70.342 79.9432 70.7693C77.7757 71.1539 75.6082 71.3676 73.3982 71.3676ZM77.5632 62.8206V44.0172C77.1807 43.9317 76.7982 43.8462 76.4157 43.8035C75.9482 43.7608 75.4807 43.718 75.0132 43.718C73.3557 43.718 71.6982 44.0599 70.2107 44.8291C68.7657 45.5556 67.5332 46.624 66.5982 47.9915C65.6632 49.359 65.1957 51.1967 65.1957 53.4189C65.1532 54.9146 65.4082 56.4103 65.9182 57.8206C66.3432 58.9744 66.9807 60.0001 67.8307 60.8548C68.6382 61.624 69.6157 62.2223 70.7207 62.5642C71.8682 62.9488 73.0582 63.1197 74.2482 63.1197C74.8857 63.1197 75.4807 63.077 76.0332 63.0343C76.5857 63.077 77.0532 62.9915 77.5632 62.8206Z" fill="#D2D2D2" />
                              </g>
                              <defs>
                                  <clipPath id="clip0_1573_358">
                                      <rect width="102" height="100" fill="white" transform="translate(0.128174)" />
                                  </clipPath>
                              </defs>
                          </svg>
                          <p className='tech-text3'>Adobe XD</p>
                    </div>

                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 103 100" fill="none">
                              <path d="M18.2907 0.5H84.53C94.2966 0.5 102.192 8.3958 102.192 18.1624V81.8376C102.192 91.6042 94.2966 99.5 84.53 99.5H18.2907C8.5241 99.5 0.628296 91.6042 0.628296 81.8376V18.1624C0.628296 8.3958 8.5241 0.5 18.2907 0.5Z" stroke="white" />
                              <path d="M41.1283 80C46.6483 80 51.1283 75.52 51.1283 70V60H41.1283C35.6083 60 31.1283 64.48 31.1283 70C31.1283 75.52 35.6083 80 41.1283 80Z" fill="#D8D8D8" />
                              <path d="M31.1283 49.5C31.1283 43.704 35.6083 39 41.1283 39H51.1283V60H41.1283C35.6083 60 31.1283 55.296 31.1283 49.5Z" fill="white" />
                              <path d="M31.1283 29C31.1283 23.48 35.6083 19 41.1283 19H51.1283V39H41.1283C35.6083 39 31.1283 34.52 31.1283 29Z" fill="#C9C9C9" />
                              <path d="M51.1283 19H61.1283C66.6483 19 71.1283 23.48 71.1283 29C71.1283 34.52 66.6483 39 61.1283 39H51.1283V19Z" fill="white" />
                              <path d="M71.1283 49.5C71.1283 55.296 66.6483 60 61.1283 60C55.6083 60 51.1283 55.296 51.1283 49.5C51.1283 43.704 55.6083 39 61.1283 39C66.6483 39 71.1283 43.704 71.1283 49.5Z" fill="#D2D2D2" />
                          </svg>
                          <p className='tech-text3'>Figma</p>
                      </div>

                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 104 100" fill="none">
                              <path d="M18.8548 0.5H85.0941C94.8607 0.5 102.757 8.3958 102.757 18.1624V81.8376C102.757 91.6042 94.8607 99.5 85.0941 99.5H18.8548C9.08819 99.5 1.19238 91.6042 1.19238 81.8376V18.1624C1.19238 8.3958 9.08819 0.5 18.8548 0.5Z" stroke="white" />
                              <path d="M31.4602 20.14L51.3695 18.0352L71.2787 20.14L86.6924 40.8314L51.3695 81.9645L16.0466 40.8314L31.4602 20.14Z" fill="#FDB300" />
                              <path d="M30.3542 40.8315L51.3695 81.9647L16.0466 40.8315H30.3542Z" fill="#ECEBEB" />
                              <path d="M72.3847 40.8315L51.3694 81.9647L86.6923 40.8315H72.3847Z" fill="#ECEBEB" />
                              <path d="M30.3541 40.8315H72.3849L51.3695 81.9647L30.3541 40.8315Z" fill="#D2D2D2" />
                              <path d="M51.3695 18.0354L31.4602 20.1402L30.3541 40.8316L51.3695 18.0354Z" fill="#D2D2D2" />
                              <path d="M51.3694 18.0354L71.2787 20.1402L72.3848 40.8316L51.3694 18.0354Z" fill="#D2D2D2" />
                              <path d="M86.6924 40.8316L71.2786 20.1401L72.3849 40.8316H86.6924Z" fill="white" />
                              <path d="M16.0466 40.8316L31.4602 20.1401L30.3542 40.8316H16.0466Z" fill="white" />
                              <path d="M51.3695 18.0354L30.3541 40.8316H72.3849L51.3695 18.0354Z" fill="white" />
                          </svg>
                          <p className='tech-text3'>Sketch</p>
                      </div>

                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 103 100" fill="none">
                              <path d="M18.419 0.5H84.6583C94.4249 0.5 102.321 8.3958 102.321 18.1624V81.8376C102.321 91.6042 94.4249 99.5 84.6583 99.5H18.419C8.65239 99.5 0.756592 91.6042 0.756592 81.8376V18.1624C0.756592 8.3958 8.65239 0.5 18.419 0.5Z" stroke="white" />
                              <path d="M26.3412 49.7343C26.3412 59.4917 32.0116 67.9239 40.2342 71.9198L28.4754 39.7021C27.1076 42.7679 26.3412 46.1606 26.3412 49.7343Z" fill="#D2D2D2" />
                              <path d="M67.6331 48.4907C67.6331 45.4441 66.5388 43.3343 65.6002 41.6921C64.3507 39.6616 63.1794 37.942 63.1794 35.9115C63.1794 33.6455 64.8981 31.5361 67.3189 31.5361C67.4282 31.5361 67.5318 31.5497 67.6383 31.5558C63.2526 27.5379 57.4096 25.0847 50.9919 25.0847C42.38 25.0847 34.8034 29.5032 30.3956 36.1957C30.974 36.2131 31.519 36.2253 31.982 36.2253C34.5604 36.2253 38.5516 35.9124 38.5516 35.9124C39.8804 35.8341 40.0371 37.7858 38.7097 37.943C38.7097 37.943 37.3743 38.1001 35.8883 38.178L44.8651 64.879L50.2597 48.6999L46.4191 38.177C45.0917 38.0992 43.8341 37.942 43.8341 37.942C42.5057 37.8642 42.6615 35.8332 43.9898 35.9115C43.9898 35.9115 48.0608 36.2244 50.483 36.2244C53.0609 36.2244 57.0526 35.9115 57.0526 35.9115C58.3824 35.8332 58.5386 37.7849 57.2107 37.942C57.2107 37.942 55.8725 38.0992 54.3893 38.177L63.2976 64.6759L65.7564 56.4595C66.8221 53.0499 67.6331 50.601 67.6331 48.4907Z" fill="white" />
                              <path d="M51.4244 51.8906L44.0283 73.3823C46.2366 74.0315 48.5721 74.3866 50.9919 74.3866C53.8626 74.3866 56.6155 73.8903 59.1779 72.9893C59.1118 72.8837 59.0517 72.7716 59.0025 72.6497L51.4244 51.8906Z" fill="#D2D2D2" />
                              <path d="M72.622 37.9075C72.728 38.6927 72.788 39.5356 72.788 40.4423C72.788 42.9437 72.3209 45.7557 70.9137 49.2718L63.3839 71.0421C70.7125 66.7685 75.6418 58.8288 75.6418 49.7347C75.6423 45.449 74.5475 41.4188 72.622 37.9075Z" fill="#D2D2D2" />
                              <path d="M50.992 21C35.1477 21 22.2566 33.8902 22.2566 49.7344C22.2566 65.5806 35.1477 78.4703 50.992 78.4703C66.8358 78.4703 79.7287 65.5806 79.7287 49.7344C79.7283 33.8902 66.8358 21 50.992 21ZM50.992 77.1532C35.8743 77.1532 23.5742 64.8531 23.5742 49.7344C23.5742 34.6167 35.8738 22.3176 50.992 22.3176C66.1092 22.3176 78.4083 34.6167 78.4083 49.7344C78.4083 64.8531 66.1092 77.1532 50.992 77.1532Z" fill="white" />
                          </svg>
                          <p className='tech-text3'>WordPress</p>
                      </div>


                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 104 100" fill="none">
                              <path d="M18.9831 0.5H85.2224C94.989 0.5 102.885 8.3958 102.885 18.1624V81.8376C102.885 91.6042 94.989 99.5 85.2224 99.5H18.9831C9.21648 99.5 1.32068 91.6042 1.32068 81.8376V18.1624C1.32068 8.3958 9.21648 0.5 18.9831 0.5Z" stroke="white" />
                              <path d="M72.1965 31.5534C72.1549 31.2504 71.8902 31.0823 71.6705 31.064C71.4525 31.0457 67.1811 30.9808 67.1811 30.9808C67.1811 30.9808 63.6088 27.5118 63.2559 27.1589C62.9031 26.806 62.2139 26.9125 61.9459 26.9924C61.9426 26.9941 61.2751 27.2005 60.1515 27.5484C59.9634 26.9391 59.687 26.1901 59.2925 25.4377C58.0208 23.0107 56.1581 21.7272 53.9075 21.7239C53.9042 21.7239 53.9025 21.7239 53.8992 21.7239C53.7427 21.7239 53.5879 21.7389 53.4315 21.7522C53.3649 21.6723 53.2983 21.5941 53.2284 21.5175C52.2479 20.4688 50.9912 19.9578 49.4847 20.0027C46.5783 20.086 43.6836 22.185 41.3365 25.9137C39.6852 28.5371 38.4284 31.8331 38.0722 34.3849C34.7346 35.4186 32.4008 36.141 32.3492 36.1577C30.6647 36.687 30.6114 36.7386 30.3917 38.3267C30.2285 39.5268 25.8207 73.6113 25.8207 73.6113L62.7599 80L78.77 76.0199C78.77 76.0199 72.2381 31.8564 72.1965 31.5534ZM58.3021 28.121C57.4515 28.384 56.4843 28.6836 55.4356 29.0082C55.414 27.5367 55.2392 25.4893 54.5534 23.7198C56.759 24.1376 57.8443 26.6328 58.3021 28.121ZM53.503 29.6075C51.5671 30.2067 49.4547 30.8609 47.3357 31.5168C47.9316 29.2346 49.0619 26.9624 50.4502 25.4726C50.9662 24.9183 51.6886 24.3007 52.5442 23.9478C53.3482 25.6258 53.523 28.0011 53.503 29.6075ZM49.5429 21.937C50.2254 21.922 50.7997 22.0718 51.2908 22.3948C50.5051 22.8026 49.746 23.3885 49.0336 24.1526C47.1875 26.1335 45.7726 29.208 45.2083 32.1743C43.4488 32.7186 41.7276 33.253 40.1429 33.7424C41.1434 29.0732 45.0568 22.0668 49.5429 21.937Z" fill="white" />
                              <path d="M71.6722 31.0658C71.4542 31.0475 67.1828 30.9826 67.1828 30.9826C67.1828 30.9826 63.6106 27.5136 63.2577 27.1607C63.1262 27.0292 62.9481 26.9609 62.7616 26.9326L62.7633 79.9985L78.7718 76.0201C78.7718 76.0201 72.2399 31.8582 72.1983 31.5552C72.1566 31.2522 71.8903 31.0841 71.6722 31.0658Z" fill="#D2D2D2" />
                              <path d="M53.8875 39.2971L52.0282 46.2534C52.0282 46.2534 49.9541 45.3096 47.4955 45.4644C43.8899 45.6925 43.8516 47.9663 43.8883 48.5373C44.0847 51.6484 52.2695 52.3276 52.729 59.6152C53.0902 65.3481 49.6877 69.2699 44.7855 69.5795C38.9011 69.9507 35.6617 66.48 35.6617 66.48L36.9086 61.1766C36.9086 61.1766 40.1695 63.6369 42.7796 63.4721C44.4842 63.3639 45.0934 61.9773 45.0318 60.9968C44.7755 56.9385 38.1104 57.1782 37.6893 50.5098C37.3347 44.8985 41.0202 39.2122 49.1517 38.6995C52.2845 38.4981 53.8875 39.2971 53.8875 39.2971Z" fill="#D2D2D2" />
                          </svg>
                          <p className='tech-text3'>Shopify</p>
                      </div>


                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 103 100" fill="none">
                              <path d="M18.5472 0.5H84.7865C94.5531 0.5 102.449 8.3958 102.449 18.1624V81.8376C102.449 91.6042 94.5531 99.5 84.7865 99.5H18.5472C8.78057 99.5 0.884766 91.6042 0.884766 81.8376V18.1624C0.884766 8.3958 8.78057 0.5 18.5472 0.5Z" stroke="white" />
                              <path d="M65.1648 43.235L60.6966 57.1558C60.6966 56.0896 57.615 33 57.615 33C50.5274 33 46.7678 37.9652 44.7956 43.235L39.3413 57.1862C39.3413 56.181 38.5709 43.3568 38.5709 43.3568C38.3078 40.5807 37.0239 37.9963 34.9623 36.0931C32.9007 34.19 30.2047 33.1003 27.3848 33.0305L33.1781 68C40.5739 68 44.5799 63.0348 46.6446 57.765L51.2669 45.8851C51.2669 46.3725 54.3485 68 54.3485 68C57.3727 67.9962 60.3172 67.0405 62.755 65.2714C65.1929 63.5024 66.9975 61.0119 67.9074 58.161L78.3848 33C70.989 33 67.1062 37.9652 65.134 43.235H65.1648Z" fill="white" />
                          </svg>
                          <p className='tech-text3'>WebFlow</p>
                      </div>

                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 104 100" fill="none">
                              <path d="M19.1114 0.5H85.3507C95.1173 0.5 103.013 8.3958 103.013 18.1624V81.8376C103.013 91.6042 95.1173 99.5 85.3507 99.5H19.1114C9.34478 99.5 1.44897 91.6042 1.44897 81.8376V18.1624C1.44897 8.3958 9.34478 0.5 19.1114 0.5Z" stroke="white" />
                              <path d="M31.5879 72.0682L26.949 20H77.9488L73.3099 72.0401L52.4067 77.8317" fill="#B5B5B5" />
                              <path d="M52.4489 73.4036V24.2734H73.2958L69.3176 68.6944" fill="#A3A3A3" />
                              <path d="M36.4236 30.6414H52.4489V37.0234H43.4241L44.0145 43.56H52.4489V49.9279H38.1667M38.4478 53.133H44.858L45.3078 58.2358L52.4489 60.1476V66.8107L39.3475 63.1558" fill="#EDEDED" />
                              <path d="M68.418 30.6414H52.4208V37.0234H67.8276M67.2512 43.56H52.4208V49.942H60.2929L59.5478 58.2358L52.4208 60.1476V66.7826L65.494 63.1559" fill="white" />
                          </svg>
                          <p className='tech-text3'>HTML5</p>
                      </div>

                      <div className='techstack-div4'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 104 100" fill="none">
                              <path d="M18.6755 0.5H84.9148C94.6814 0.5 102.577 8.3958 102.577 18.1624V81.8376C102.577 91.6042 94.6814 99.5 84.9148 99.5H18.6755C8.90886 99.5 1.01306 91.6042 1.01306 81.8376V18.1624C1.01306 8.3958 8.90886 0.5 18.6755 0.5Z" stroke="white" />
                              <path d="M79.6545 21L74.9954 73.1939L54.0525 79L33.1672 73.2021L28.5131 21H79.6545Z" fill="#B5B5B5" />
                              <path d="M71.0066 69.8704L74.9882 25.2683H54.0837V74.5621L71.0066 69.8704Z" fill="#A3A3A3" />
                              <path d="M39.1827 44.6294L39.7566 51.0317H54.0839V44.6294H39.1827Z" fill="#EDEDED" />
                              <path d="M54.0839 31.6707H54.0618H38.0298L38.6118 38.0731H54.0839V31.6707Z" fill="#EDEDED" />
                              <path d="M54.0838 67.9179V61.2567L54.0558 61.2642L46.9254 59.3389L46.4696 54.2327H43.0048H40.0426L40.9396 64.2853L54.0544 67.9261L54.0838 67.9179Z" fill="#EDEDED" />
                              <path d="M61.9455 51.0315L61.2023 59.3349L54.0616 61.2622V67.9231L67.1869 64.2855L67.2832 63.2038L68.7876 46.3484L68.9439 44.6292L70.0997 31.6707H54.0616V38.0731H63.0832L62.5007 44.6292H54.0616V51.0315H61.9455Z" fill="white" />
                          </svg>
                          <p className='tech-text3'>CSS3</p>
                      </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TechStack
